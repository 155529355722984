<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="horizontal-timeline">
          <svg :width="totalWidth" height="200" :class="{ dark: isDarkTheme }">
            <title>{{ title }}</title>
            <!-- Define arrowhead marker -->
            <defs>
              <marker
                id="arrowhead"
                markerWidth="20"
                markerHeight="20"
                refX="10"
                refY="10"
                orient="auto"
                markerUnits="userSpaceOnUse"
              >
                <polygon points="0 0, 20 10, 0 20" :fill="lineColor" />
              </marker>
            </defs>

            <!-- Draw the timeline line with arrowhead marker -->
            <line
              x1="0"
              y1="40"
              :x2="totalWidth"
              y2="40"
              :stroke="lineColor"
              stroke-width="20"
              marker-end="url(#arrowhead)"
            />
            <g v-for="(item, index) in eval_list" :key="index">
              <rect
                :x="getSegmentX(index)"
                y="30"
                :width="getSegmentWidth(index)"
                height="20"
                :fill="item.color"
                @mouseover="showTooltip(index)"
                @mouseout="hideTooltip"
              />
              <text
                v-if="showDateLabel(index)"
                :x="getSegmentX(index)"
                :y="index !== 0 ? 80 : 25"
                text-anchor="start"
                :fill="textColor"
                :transform="
                  index !== 0
                    ? 'rotate(-45, ' + (getSegmentX(index) + 20) + ', 160)'
                    : ''
                "
              >
                {{ item.eval_date }}
              </text>
            </g>
            <text
              :x="
                getSegmentX(eval_list.length - 1) +
                getSegmentWidth(eval_list.length - 1)
              "
              y="25"
              text-anchor="end"
              :fill="textColor"
            >
              {{ today }}
            </text>
          </svg>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    evaluation_list: Array,
  },
  data() {
    return {
      title: "",
      eval_list: [],
    };
  },
  mounted() {
    if (this.evaluation_list) {
      this.evaluation_list.forEach((element) => {
        let e = Object.assign({}, element);
        this.eval_list.push(e);
      });
      this.sortPipelineData();
    }
  },
  computed: {
    totalDuration() {
      return this.eval_list.length > 0
        ? this.eval_list.reduce((sum, item, index, arr) => {
            if (index === arr.length - 1) {
              const lastDate = new Date(item.eval_date);
              const todayDate = new Date(this.today);
              return (
                sum + Math.abs(todayDate - lastDate) / (1000 * 60 * 60 * 24)
              );
            }
            const currentDate = new Date(item.eval_date);
            const nextDate = new Date(arr[index + 1].eval_date);
            nextDate.setDate(nextDate.getDate() - 1);
            return (
              sum + Math.abs(nextDate - currentDate) / (1000 * 60 * 60 * 24)
            );
          }, 0)
        : 0;
    },
    segmentWidthUnit() {
      return 1400 / this.totalDuration; // Assume total available width is 800px
    },
    totalWidth() {
      return this.totalDuration * this.segmentWidthUnit;
    },

    isDarkTheme() {
      return this.$vuetify.theme.dark;
    },
    textColor() {
      return this.isDarkTheme ? "#fff" : "#000";
    },
    lineColor() {
      return this.isDarkTheme ? "#ccc" : "#000";
    },
    today() {
      return new Date().toISOString().split("T")[0];
    },
  },
  methods: {
    getSegmentX(index) {
      if (this.eval_list.length > 0)
        if (index === this.eval_list.length) {
          return this.getSegmentX(index - 1) + this.getSegmentWidth(index - 1);
        }
      let x = 0;
      for (let i = 0; i < index; i++) {
        x += this.getSegmentDuration(i) * this.segmentWidthUnit;
      }
      return x;
    },
    getSegmentWidth(index) {
      return this.getSegmentDuration(index) * this.segmentWidthUnit;
    },
    getSegmentDuration(index) {
      if (this.eval_list.length > 0) {
        if (index === this.eval_list.length - 1) {
          const lastDate = new Date(this.eval_list[index].eval_date);
          const todayDate = new Date(this.today);
          const diffTime = Math.abs(todayDate - lastDate);
          return diffTime / (1000 * 60 * 60 * 24); // Convert milliseconds to days
        }
        const prevDate = new Date(this.eval_list[index].eval_date);
        const nextDate = new Date(this.eval_list[index + 1].eval_date);
        // Subtract one day from nextDate
        nextDate.setDate(nextDate.getDate() - 1);
        const diffTime = Math.abs(nextDate - prevDate);
        return diffTime / (1000 * 60 * 60 * 24); // Convert milliseconds to days
      }
    },
    eval_date(index) {
      const nextDate = new Date(this.eval_list[index].eval_date);
      if (index > 0) nextDate.setDate(nextDate.getDate() - 1);
      let currDate = nextDate.toISOString().slice(0, 10);
      return currDate;
    },
    showTooltip(index) {
      if (index >= 0) {
        let currDate;
        const prevDate = this.eval_list[index].eval_date;

        if (index === this.eval_list.length - 1) currDate = this.today;
        else {
          const nextDate = new Date(this.eval_list[index + 1].eval_date);
          nextDate.setDate(nextDate.getDate() - 1);
          currDate = nextDate.toISOString().slice(0, 10);
        }
        this.title =
          this.eval_list[index].status_name +
          " (from " +
          prevDate +
          " to " +
          currDate +
          ")";
      }
    },
    hideTooltip() {
      this.title = "";
    },
    sortPipelineData() {
      if (this.eval_list.length > 0)
        this.eval_list.sort(
          (a, b) => new Date(a.eval_date) - new Date(b.eval_date)
        );
    },
    showDateLabel(index) {
      const currX = this.getSegmentWidth(index);
      return currX > 20; // Only show if space between segments is more than 50px
    },
  },
};
</script>

<style scoped>
.horizontal-timeline {
  position: relative;
}

.tooltip {
  transition: opacity 0.2s ease-in-out;
}
</style>
